import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as EuMigrationIcon } from '../assets/eu-migration.svg';

const topic = {
  "title": "EU workers",
  "results": {
    "deal": {
      "status": "blue",
      "comment": "Free movement until 2020 but no replacement yet agreed"
    },
    "disorderly": {
      "status": "amber",
      "comment": "Merit based system possible but could lead to labour shortage in many sectors"
    },
    "remain": {
      "status": "green",
      "comment": "EU workers fill vital roles and we are able to repatriate those who have not found a job after 6 months"
    },
    "reform": {
      "status": "green",
      "comment": "EU workers fill vital roles and we are able to repatriate those who have not found a job after 6 months"
    }
  }
};

const EuMigrationTopic = () => (
  <Topic topic={topic} icon={EuMigrationIcon}>
    <p>
      The UK has the ability to implement more immigration control than it
      currently does. We already have the option of exit checks at air and
      sea ports, but do not use them. We retain the right to repatriate EU
      migrants who have not found a job after 6 months, but we do not have
      a system for this
      <OutboundLink href="https://www.theguardian.com/commentisfree/2017/jul/31/britain-take-back-control-immigration-eu-directive-brexit">(source)</OutboundLink>.
    </p>
    <p>
      Replacing freedom of movement with a merit based system could lead
      to a labour shortage for many jobs. Initially, in a no deal scenario,
      the transition period would likely lead to a shortage across all
      sectors as employment is the UK is currently at a high. Following the
      implementation of a merit based system, we would only see enough
      immigration from workers with specific skills, but sectors such as
      hospitality and agriculture could see serious staff shortages. It has
      been implied that the UK could prevent workers from coming here unless
      they earn £30k per year, leaving jobs that pay lower unfilled
      <OutboundLink href="https://www.ft.com/content/36baacce-ddd0-11e8-9f04-38d397e6661c">(source)</OutboundLink>.
    </p>
  </Topic>
);

export default EuMigrationTopic;
