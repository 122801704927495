import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as FreeMovementIcon } from '../assets/sunbed.svg';

const topic = {
  "title": "Freedom of Movement",
  "results": {
    "deal": {
      "status": "red",
      "comment": "Brits will lose the automatic right to live and work in any EU country"
    },
    "disorderly": {
      "status": "red",
      "comment": "Brits will lose the automatic right to live and work in any EU country"
    },
    "remain": {
      "status": "green",
      "comment": "Brits have the right to live and work in any EU country"
    },
    "reform": {
      "status": "green",
      "comment": "Brits have the right to live and work in any EU country"
    }
  }
};

const FreeMovementTopic = () => (
  <Topic topic={topic} icon={FreeMovementIcon}>
<p>As EU citizens, British people can live and work in any EU country they choose. If Brexit goes ahead, this will change. By ending freedom of movement, our right to live and work anywhere ends. We can no longer simply retire in Spain, spend a gap year in Germany or take a summer job in Ibiza.</p>
<p>After Brexit any British people wanting to move to Europe will be treated the same as any EU citizens wanting to live here. This could mean having to earn a minimum salary of £30,000/year along with many other caveats and restrictions.</p>
  </Topic>
);

export default FreeMovementTopic;
