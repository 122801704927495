import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as EconomyIcon } from '../assets/economy.svg';

const topic = {
  "title": "Welfare and benefits",
  "results": {
    "deal": {
      "status": "blue",
      "comment": "No changes to the welfare system for EU citizens have been laid out"
    },
    "disorderly": {
      "status": "blue",
      "comment": "No changes to the welfare system for EU citizens have been laid out"
    },
    "remain": {
      "status": "amber",
      "comment": "EU migrants can claim limited welfare such as jobseekers after 3 months"
    },
    "reform": {
      "status": "green",
      "comment": "EU migrants can use welfare system only after working and paying contributions for 4 years"
    }
  }
};

const WelfareTopic = () => (
  <Topic topic={topic} icon={EconomyIcon}>
    <p>Currently EU migrants can claim limited welfare such as jobseekers after being in the UK for 3 months. 
      </p>
    <p>
      Before the referendum David Cameron negotiated with the EU to ensure that migrants must be working
      and paying into the system in the UK for 4 years before being able to make
      full use of Britain's welfare system. The EU has agreed to this, but it
      would only come into effect if Britain were to remain in the EU.
      <OutboundLink href="https://www.bbc.co.uk/news/uk-politics-eu-referendum-36449974" target="_blank">(source)</OutboundLink>
    </p>
    <p>
      It is currently unknown if there would be any chages to welfare laws under
      the Withdrawal Agreement/Johnson's deal or in the event of a "no deal" Brexit
    </p>
  </Topic>
);

export default WelfareTopic;
