import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as TravelIcon } from '../assets/travel.svg';

const topic = {
  "title": "Blue passport",
  "results": {
    "deal": {
      "status": "green",
      "comment": "Britain can currently choose its own passport colour and this won't change if we leave"
    },
    "disorderly": {
      "status": "green",
      "comment": "Britain can currently choose its own passport colour and this won't change if we leave"
    },
    "remain": {
      "status": "green",
      "comment": "Britain can currently choose its own passport colour"
    },
    "reform": {
      "status": "green",
      "comment": "Britain can currently choose its own passport colour"
    }
  }
};

const PassportTopic = () => (
  <Topic topic={topic} icon={TravelIcon}>
    <p>
      The EU advises a burgundy passport for uniformity but does not enforce it.
      The UK decided to adopt this colour of its own accord. Croatia is a member
      of the EU and has a blue passport. There is no reason the UK cannot have a
      blue passport if it stays in the EU.
      <OutboundLink href="https://www.thejournal.ie/factcheck-passport-3766834-Dec2017/">(source)</OutboundLink>.
    </p>
  </Topic>
);

export default PassportTopic;
