import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as IrishBorderIcon } from '../assets/irish-border.svg';

const topic = {
  "title": "Irish border",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "Boris: Border between GB&nbsp;&&nbsp;NI </br> May: Possible border between GB & NI (backstop)"
    },
    "disorderly": {
      "status": "red",
      "comment": "A hard border between ROI & NI would be needed if we leave with no deal"
    },
    "remain": {
      "status": "green",
      "comment": "No border issue as both countries within the EU"
    },
    "reform": {
      "status": "green",
      "comment": "No border issue as both countries within the EU"
    }
  }
};

const IrishBorderTopic = () => (
  <Topic topic={topic} icon={IrishBorderIcon}>
      <p>
        Under the terms of the Withdrawal Agreement it is likely that the only
        way to both fully leave the EU and also avoid a hard border in Ireland would be to implement
        checks between NI and the rest of the UK. The only other viable way of avoiding a hard border would be for
        Britain to stay within a customs union with the EU indefinitely.</p>
<p>However, the Withdrawal Agreement does give both sides time to work this out in case an alternative can be reached. In a No Deal scenario we would not have time to put any alternative in place which would lead to an immediate hard border in Ireland, something that the UK, Ireland and the EU all do not want as it contravenes the Good Friday Agreement.<OutboundLink href="https://www.theguardian.com/politics/2019/jan/22/no-deal-brexit-would-mean-hard-irish-border-eu-confirms">(source)</OutboundLink>
      </p>
    <p>Johnson's deal simply replaces the possibility of checks between GB and NI with certainty of it. <OutboundLink href="https://www.theguardian.com/politics/2019/oct/17/how-is-boris-johnson-brexit-deal-different-from-theresa-may">(source)</OutboundLink>
    </p>
<p>Currently, there is no border issue as both the Republic of Ireland and the United Kingdom are within the EU and checks are only needed when goods are entering the EU.</p>
  </Topic>
);

export default IrishBorderTopic;
