import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as NonEuMigrationIcon } from '../assets/world-migration.svg';

const topic = {
  "title": "Non EU immigration",
  "results": {
    "deal": {
      "status": "green",
      "comment": "Not affected by EU membership"
    },
    "disorderly": {
      "status": "green",
      "comment": "Not affected by EU membership"
    },
    "remain": {
      "status": "green",
      "comment": "Not affected by EU membership"
    },
    "reform": {
      "status": "green",
      "comment": "Not affected by EU membership"
    }
  }
};

const NonEuMigrationTopic = () => (
  <Topic topic={topic} icon={NonEuMigrationIcon}>
    <p>
      The UK is currently able to control non-EU immigration as it sees fit, without restriction by the European Union
    </p>
  </Topic>
);

export default NonEuMigrationTopic;
