import React from 'react';
import PropTypes from 'prop-types';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import IntroText from '../components/IntroText';
import Notes from '../components/Notes';
import DealHeadersRow from '../components/DealHeadersRow';
import Meta from '../components/Meta';
import PromptBoxes from '../components/PromptBoxes';
import TopicHeader from '../components/TopicHeader';

import IrishBorderTopic from '../topics/irish-border';
import NonEuMigrationTopic from '../topics/non-eu-migration';
import EuMigrationTopic from '../topics/eu-migration';
import WelfareTopic from '../topics/welfare';
import PassportTopic from '../topics/passport';
import FreeMovementTopic from '../topics/free-movement';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Link = (({ href, children }) => (
  <OutboundLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </OutboundLink>
));

const ImmigrationAndBorders = ({ classes, className, location }) => (
  <Layout location={location}>
    <Meta location={location} title="Immigration &amp; Borders" shareImage="immigration" description="See how various Brexits affect immigration" />
    <IntroText />
    <TopicHeader title="Immigration &amp; Borders" />
    <DealHeadersRow />
    <NonEuMigrationTopic />
    <EuMigrationTopic />
    <WelfareTopic />
    <FreeMovementTopic />
    <IrishBorderTopic />
    <PassportTopic />
    <PromptBoxes/>
    <Notes/>
  </Layout>
);

ImmigrationAndBorders.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

ImmigrationAndBorders.defaultProps = {
  data: {},
};

export default withRoot(ImmigrationAndBorders);
